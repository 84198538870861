$(document).ready(function() {
  var windowWidth = $(window).width(),
      heroHeight  = $('.hero_section').outerHeight();

  $('.why-us-hero-particles').height(heroHeight);

  if (windowWidth > 767) {
    particlesJS.load('particlesred-js', 'assets/build/particlesred.json');
    particlesJS.load('particlesblack-js', 'assets/build/particlesblack.json');
    particlesJS.load('particlesblue-js', 'assets/build/particlesblue.json');
    particlesJS.load('particlesorange-js', 'assets/build/particlesorange.json');
    particlesJS.load('particlesred-js-expect', 'assets/build/particlesred.json');
    particlesJS.load('particlesblack-js-expect', 'assets/build/particlesblack.json');
    particlesJS.load('particlesblue-js-expect', 'assets/build/particlesblue.json');
    particlesJS.load('particlesorange-js-expect', 'assets/build/particlesorange.json');
    particlesJS.load('particlesred-js-thanks', 'assets/build/particlesred.json');
    particlesJS.load('particlesblack-js-thanks', 'assets/build/particlesblack.json');
    particlesJS.load('particlesblue-js-thanks', 'assets/build/particlesblue.json');
    particlesJS.load('particlesorange-js-thanks', 'assets/build/particlesorange.json');
  }
});
