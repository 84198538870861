$(function(){
  admissionsNav();
  admissionsNavMobile();
  programsMenuMobile();
  faq();
});

$(document).ready(function () {
  var menu_visible = false;

  $(".navbar-toggle").click(function(){
    if (menu_visible === false) {
      $("nav").toggleClass("navbar-open");
      $(".mainLogo").hide();
      $(".whiteLogo").show();
      menu_visible = true;
    }else{
      $("nav").toggleClass("navbar-open");
      $(".mainLogo").show();
      $(".whiteLogo").hide();
      menu_visible = false;
    };
  });

  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });

  $("body").scrollspy({target: "#navbar", offset: 280});

  $(function() {
    $('a[href*="#"]:not([href="#"])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        scrollNN = definescroll();
        if (target.length) {
          $('html, body').animate({
            scrollTop: target.offset().top - scrollNN
          }, 1000);
          return false;
        }
      }
    });

    $('#dropdown-mobile li a').click(function() {
      var newText = $(this).text();
      $('body').trigger("click");
      $('#dropdown-mobile-button').html(newText + ' <span class="caret"></span>');

    });
  });

  $(document).on("scroll", onScroll);

});

function admissionsNav(){
  if($("#admissions-nav").length) {
    $('#admissions-nav nav').affix({
      offset: {     
        top: $('#admissions-nav nav').offset().top - 101,
      }
    });
  }
}

function admissionsNavMobile(){
  if($("#admissions-navm").length) {
    $('#admissions-navm').affix({
      offset: {     
        top: $('#admissions-navm').offset().top - 101,
      }
    });
  }
}

function definescroll() {
  var windowWidth = $(window).width();
  if (windowWidth > 767) {
    return 100;
  }
  return 115;
}

function programsMenuMobile(){
  if($("#programs-menu-mobile").length) {
    $('#programs-menu-mobile').affix({
      offset: {     
        top: $('.programs-tabs').offset().top - 106,
      }
    });
  }
}

function faq(){
  $(".question").click(function(){
   $(this).next(".answer").fadeToggle();
   $(this).toggleClass('question-open');
 });
}

function onScroll(event){
  var scrollPos = $(document).scrollTop();
  $('.aplication-main section').each(function () {
    var currLink = $(this);
    if (currLink.position().top <= scrollPos + 200 && currLink.position().top + currLink.height() > scrollPos) {
      var newText = $(currLink).attr("data-section");
      $('#dropdown-mobile-button').html(newText + ' <span class="caret"></span>');
    } else if (scrollPos < 500) {
      $('#dropdown-mobile-button').html('APPLICATION PROCESS <span class="caret"></span>');
    }
  });
}

