$(document).ready(function(){
  if($('#programs-content').size()) {

    var slides = ['infant', 'toddler', 'primary', 'elementary', 'adolescent', 'bilingual', 'physical_education', 'before_after_care'];
    var hash = location.hash.replace('#','');
    var a = slides.indexOf(hash);
    if (a >= 0) {
      $('html, body').animate({
        scrollTop: $('.programs-tabs').offset().top - 120
      }, 500);
    }else if (a == -1) {a = 0}

    slick(slides, a);
  }
});

function slick(slides, a){

  var option, slider;
  option = $('.programs-nav .option');
  slider = $('#programs-content .slider');

  option.on('click', function() {
    var slide, that;
    var hash = location.hash.replace('#','');

    that = $(this);
    slide = that.attr('slide');
    option.removeClass('is-active');
    slider.slick('slickGoTo', slide);

    $('html, body').animate({
      scrollTop: $('.programs-tabs').offset().top - 151
    }, 500);

    location.hash = slides[slide];

    return that.addClass('is-active');
  });

  $('#programs-content .slider').slick({
    arrows: false,
    dots: false,
    swipe: false,
    fade: true,
    adaptiveHeight: true,
    responsive: [
      {
      breakpoint: 767,
      settings: {
        arrows: false,
        // asNavFor: '.programs-nav_mobile',
        centerMode: false,
        focusOnSelect: false
        }
      }
    ]
  });

  $(window).on('load', function() {
    var asNavForValue = [
      {
      breakpoint: 767,
      settings: {
        asNavFor: '.programs-nav_mobile'
        }
      }
    ];
    $('#programs-content .slider').slick('slickSetOption', 'responsive', asNavForValue, true);
  });

  $('#programs-content .slider').slick('slickGoTo', a);

  $('.programs-nav .option').each(function() {
    slide = $(this).attr('slide');
    if (slide == a) {$(this).addClass('is-active')} else {$(this).removeClass('is-active');}

  });

  $('.programs-nav_mobile').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    asNavFor: '#programs-content .slider',
    centerMode: false,
    arrows: false,
    focusOnSelect: false,
  });

  $('.programs-nav_mobile').slick('slickGoTo', a);

  $('.back').click(function(){
    $("#programs-content .slider").slick("slickPrev");
  });

  $('.next').click(function(){
    $("#programs-content .slider").slick("slickNext");
  });
}
