;(function(window,$,undefined) {

  function ready() {
    $('#slider1').slick({
      autoplay: true,
      autoplaySpeed: 8000,
      arrows: true,
      responsive: [
        {
        breakpoint: 767,
        settings: {
          autoplay: true,
          autoplaySpeed: 8000,
          arrows: false
          }
        }
      ]
    });

    // On before slide change
    $('#slider1').on('afterChange', function(event, slick, currentSlide, nextSlide){
      $('#number').text(currentSlide + 1);
    });

    $('#slider2').slick({
      autoplay: true,
      autoplaySpeed: 8000,
      arrows: true,
      responsive: [
        {
        breakpoint: 767,
        settings: {
          autoplay: true,
          autoplaySpeed: 8000,
          arrows: false
          }
        }
      ]
    });

    // On before slide change
    $('#slider2').on('afterChange', function(event, slick, currentSlide, nextSlide){
      $('#number2').text(currentSlide + 1);
    });
  }

  $(document).ready(function(){
    if($('#slider1').size()) {
      ready();
    }
  });

})(window,jQuery);