;(function(window,$,undefined) {

  function ready() {
    var config = {
      viewFactor : 0.15,
      duration   : 1000,
      distance   : "0px",
      scale      : 0.1
    };

    window.sr = ScrollReveal( config );

    sr.reveal( ".program-circles-b .program-circle.seq1", { reset: false, delay: 100, viewOffset: { top: 50 } });
    sr.reveal( ".program-circles-b .program-circle.seq2", { reset: false, delay: 300, viewOffset: { top: 50 } } );
    sr.reveal( ".program-circles-b .program-circle.seq3", 
      { 
        reset: false, 
        delay: 500, 
        viewOffset: { top: 50 },
        afterReveal : function(e) {
          $('.program-circle').addClass('anim');
        }
      });
  }

  $(document).ready(function(){
    if($('.program-circles-b').size()) {
      ready();
    }
  });

})(window,jQuery);