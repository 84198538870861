;(function(window,$,undefined) {

  function ready() {
    var bajando = false, subiendo = false;
    var heightHero = $('.hero').height();
   
    $('html').on('DOMMouseScroll mousewheel', function (e) {
      if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
        bajando = true;
        subiendo = false;
      } else if (e.originalEvent.wheelDelta > 0) {
        bajando = false;
        subiendo = true;
      }

      if ($(window).scrollTop() > 1) {
        if (e.originalEvent.detail > 0 || e.originalEvent.wheelDelta < 0) {
          //scroll down
          //$( ".mainNav" ).addClass( "hide-nav-bar" );
          //$(".affix").addClass("affix-top");
        } else if (e.originalEvent.wheelDelta > 0) {
          //scroll up
          //$( ".mainNav" ).removeClass( "hide-nav-bar" );
          //$(".affix").removeClass("affix-top");
        } else if (e.originalEvent.wheelDelta == 0) {
          if (bajando) {
            //$( ".mainNav" ).addClass( "hide-nav-bar" );
            //$(".affix").addClass("affix-top");
          }else if (subiendo) {
            //$( ".mainNav" ).removeClass( "hide-nav-bar" );
            //$(".affix").removeClass("affix-top");
          }
        }
        $('.mainNav').addClass('small');
        //$('.mainNav').addClass('navbar-fixed-top');
        //$('.hero').not(".heroHome").addClass('navbar-fixed-top-hero');
      } else {

        $('.mainNav').removeClass('small');
        //$('.mainNav').removeClass('navbar-fixed-top');
        //$('.hero').not(".heroHome").removeClass('navbar-fixed-top-hero');
        // if ($(window).scrollTop() > (heightHero * 0.95) && subiendo) {
        //   $( ".mainNav" ).addClass( "hide-nav-bar" );
        // }

        // if ($(window).scrollTop() < (heightHero * 0.9) && subiendo) {
        //   $('.mainNav').removeClass('small');
        //   $('.mainNav').removeClass('navbar-fixed-top');
        //   $('.hero').not(".heroHome").removeClass('navbar-fixed-top-hero');
        // }

        // if($(window).scrollTop() > (heightHero * 0.3) && bajando) { 
        //   $( ".mainNav" ).addClass( "hide-nav-bar" );
        // }else if($(window).scrollTop() < (heightHero * 0.3)) {
        //   $( ".mainNav" ).removeClass( "hide-nav-bar" );
        // }
      }
    });

  }

  $(document).ready(function(){
    if($('.mainNav ').size()) {
      ready();

      if ($(window).scrollTop() > 300) {
        $(".affix").addClass("affix-top");
      }
    }
  });

})(window,jQuery);