;(function(window,$,undefined) {

  function ready() {
    $('.program-circles .program-circle-parent').on('click', function() { 
      window.location.href = 'programs.html#infant'; 
    });

    $('.program-circles .program-circle-toddler').on('click', function() { 
      window.location.href = 'programs.html#toddler'; 
    });

    $('.program-circles .program-circle-primary').on('click', function() { 
      window.location.href = 'programs.html#primary'; 
    });

    $('.program-circles .program-circle-elementary').on('click', function() { 
      window.location.href = 'programs.html#elementary'; 
    });

    $('.program-circles .program-circle-adolescent').on('click', function() { 
      window.location.href = 'programs.html#adolescent'; 
    });

    $('.program-circles .program-circle-phisical').on('click', function() { 
      window.location.href = 'programs.html#phisycal_education'; 
    });

    $('.program-circles .program-circle-spanish').on('click', function() { 
      window.location.href = 'programs.html#bilingual'; 
    });

    $('.program-circles .program-circle-before').on('click', function() { 
      window.location.href = 'programs.html#before_after_care'; 
    });

    
  }

  $(document).ready(function(){
    if($('.program-circles').size()) {
      ready();
    }
  });

})(window,jQuery);